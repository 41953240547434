import { useCities } from '~/store/cites/cities.store'
import { useMainStore } from '~/store/main/main.store'

export default async function getFullTariff(input?, tariffsInput2?) {
  const ctx = useNuxtApp()
  const cityStore = useCities()
  const mainStore = useMainStore()
  const currentCity = computed(() => cityStore.getCity)
  const houseHash = computed(() => cityStore.getHouseHash)
  const houseUrl = computed(() => cityStore.getHouseUrl)
  const partner = computed(() => mainStore.getDomainConfig)
  const providerClient = computed(() => mainStore.getProviderClient)

  const client = ctx._apolloClients.default

  const res = (
    await client.query({
      query: gql`
        query getTariffs(
          $input: LocationInfoInput!
          $tariffsInput2: BaseTariffsInput
        ) {
          location {
            locationTerms(input: $input) {
              tariffs(input: $tariffsInput2) {
                data {
                  id
                  name
                  index
                  paramHash
                  markers {
                    cheaper
                    optimal
                    recommended
                    topSale
                    fakeMobile
                    fake
                    enableAutomatization
                    disabledAutomatizationReasonsDisplay
                    disabledAutomatizationReasons
                    providerRequirements {
                      birthday
                      connectionDate
                      entrance
                      floor
                    }
                  }
                  houseType
                  sortDebug
                  wireless
                  connectionTime {
                    displayShort
                    fastestConnectionProvider
                    isFast
                  }
                  payment {
                    price
                    promoPeriod
                    promoPrice
                    complexPrice {
                      price
                      fromMonth
                      promoDisplay
                      promoDisplayPercents
                      isMainPrice
                      isPromoPrice
                    }
                    promoDisplayPercents
                    prepaidPayment
                    creditPayment
                    contractPeriod
                    freeConnection
                    installPayment
            installPaymentVaries
            installPaymentMax
                  }
                  services {
                    mobileSimCount
                    tvChannelsCount
                    mobileSms
                    mobileMinutes
                    mobileGigabytes
                    internetSpeed
                    internetPortSpeed
                    internetEffectiveSpeed
                    cctvCameraCount
                    cctvStorageDays
                    wirelessInternetGb
                  }
                  subscriptions {
                    subscription {
                      name
                      id
                      icon
                      iconSmall
                      slug
                      description
                      shortDescription
                      includes {
                        id
                        icon
                        iconSmall
                        slug
                      }
                    }
                  }
                  techs {
                    id
                    name
                  }
                  provider {
                    config
                    id
                    logo
                    slug
                    name
                    promoDisplay
                    logoT {
                      url(options: { width: 300, halign: "left" })
                        unoptimized
                    }
                  }
                  info {
                    attributes {
                      id
                      key
                      name
                    }
                    benefits: byType(type: [BENEFIT]) {
                      name
                      id
                    }
                    limited: byType(type: [LIMITED]) {
                      id
                      name
                      content
                    }
                    byType(type: [BONUSES, SPECIAL_PROMOS, GAMING, UNLIMITED]) {
                      id
                      name
                      icon
                    }
                  }
                  connectionTime {
                    displayRange
                    displayMin
                    displayAvg
                    fastestConnectionProvider
                  }
                  fromAddress
                  devices {
                    recommended
                    mainPaymentMethod {
                      selected
                      cost
                      method
                      monthlyPayment
                      singlePayment
                      installmentPeriod
                      id
                      maxUse
                    }
                    fromTariff
                    device {
                      kind
                      kindName
                      name
                      portSpeed
                      id
                      description
                      image
                      imageT {
                        thumb: url(options: { width: 150, height: 150, adaptiveFitIn: true })
                        norm: url
                      }
                      otherTechs
                      properties {
                        name
                        value
                      }
                      purpose {
                        description
                        key
                        name
                      }
                      techs {
                        id
                        name
                      }
                      rating
                      text
                      wifi
                    }
                    paymentMethods {
                      monthlyPayment
                      singlePayment
                      installmentPeriod
                      maxUse
                      id
                      cost
                      display
                      description
                      method
                      selected
                    }
                  }
                }
                agg {
                    locationAgg {
                        availableProviders {
                            id
                        }
                    }
                }
              }
            }
          }
        }
      `,
      variables: {
        input: {
          hash: houseUrl.value
            ? houseHash.value || undefined
            : undefined,
          url: houseUrl.value ? houseUrl.value : undefined,
          addrObjFiasId: currentCity.value?.fias_id,
          strictTechs: !!houseUrl.value,
          renameNonUniqueTariffs: !partner.value && (!houseUrl.value),
          lowerProviders: providerClient.value ? [+providerClient.value] : undefined,
          ...input,
        },
        tariffsInput2,
      },
    })
  ).data.location.locationTerms?.tariffs
  if (!res?.agg?.locationAgg?.availableProviders?.length) {
    mainStore.$patch({
      addressWOTariffs: true,
    })
  }
  else {
    mainStore.$patch({
      addressWOTariffs: false,
    })
  }
  return res?.data?.[0]
}
