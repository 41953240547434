import { omit } from '~/helpers'

export const inputProps = {
  modelValue: {
    type: [String, Number, Object, Array] as PropType<
      string | string[] | number | Record<string, unknown>
    >,
    default: null,
  },
  width: {
    type: [String, Number] as PropType<string | number>,
    required: false,
  },
}

export type UnwrapInputProps = {
  modelValue: any | null
  width?: string | number
}

export type FormatFunction = () => string | null

type InputOptions<P> = {
  props: P
  format?: FormatFunction
  parse?: () => string | null
}

export function useWidth(
  props: Record<string, unknown> & { width?: string | number },
) {
  return computed(() => (props.width ? props.width + 'px' : '100%'))
}

export function useInput(options: InputOptions<UnwrapInputProps>) {
  const currentValue = ref<any>()
  const instance = getCurrentInstance()!
  const { props, format, parse } = options

  const formatValue = (args: any[]) => (format ? format(args) : args[0])
  const parseValue = (value: any) => (parse ? parse(value) : value)
  const onInput = (...args: any) => {
    currentValue.value = args[0]
    instance.emit('input', formatValue(args))
  }
  const onKeyDown = () => {}
  const compositionstart = (event) => {
    instance.emit('compositionstart', event)
  }
  const compositionupdate = (event) => {
    instance.emit('compositionupdate', event)
  }
  const onChange = (...args: any) => {
    currentValue.value = args[0]
    instance.emit('change', formatValue(args))
  }
  onMounted(() => {
    currentValue.value = parseValue(props.modelValue)
  })
  watch(
    () => props.modelValue,
    (value) => {
      currentValue.value = parseValue(value)
    },
  )

  return {
    currentValue,
    onInput,
    onChange,
    onKeyDown,
    compositionstart,
    compositionupdate,
    getWidth: computed(() => (props.width ? props.width + 'px' : '100%')),
    listeners: computed(() =>
      omit({ input: instance.proxy.onInput, change: instance.proxy.onChange }, [
        'input',
        'change',
      ]),
    ),
  }
}
