import { useCities } from '~/store/cites/cities.store'
import { useMainStore } from '~/store/main/main.store'

export default async function getReviews(input?, reviewsInput?, providerInput?) {
  const ctx = useNuxtApp()
  const cityStore = useCities()
  const mainStore = useMainStore()
  const currentCity = computed(() => cityStore.getCity)
  const houseUrl = computed(() => cityStore.getHouseUrl)
  const houseHash = computed(() => cityStore.getHouseHash)

  const client = ctx._apolloClients.default

  const res = await client.query({
    query: gql`
  query getProvidersReviews(
    $input: LocationInfoInput!
    $reviewsInput2: LocationProviderReviewsInput
    $providersInput2: BaseLocationProvidersInput
  ) {
    location {
      locationTerms(input: $input) {
        providers(input: $providersInput2) {
          reviews(input: $reviewsInput2) {
            page
            hasNext
            totalItems
            data {
              authorName
              commentsCount
              created
              currentUserReaction
              dislikes
              id
              likes
              locationDisplay
              positive
              provider {
                id
                logo
                name
                logoT {
                  url(options: { width: 200, halign: "left" })
                  unoptimized
                }
              }
              ratingConnection
              ratingFinal
              ratingService
              ratingSpeed
              ratingStability
              text
            }
          }
        }
      }
    }
  }
`,
    variables: {
      input: {
        hash: houseUrl.value
          ? houseHash.value || undefined
          : undefined,
        url: houseUrl.value ? houseUrl.value : undefined,
        addrObjFiasId: currentCity.value?.fias_id,
        strictTechs: !!houseUrl.value,
        ...input,
      },
      reviewsInput2: {
        ...reviewsInput,
      },
      providersInput2: {
        ...providerInput,
      },
    },
  })

  return res.data.location.locationTerms.providers.reviews
}
