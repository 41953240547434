<template>
  <LazyProviderPageProviderReviewsPagePartners v-if="diProvider" />
  <LazyProviderPageProviderReviewsPage v-else />
</template>

<script setup lang="ts">
import { useMainStore } from '~/store/main/main.store'

const mainStore = useMainStore()
const diProvider = computed(() => mainStore.getDiProvider)
</script>

<style scoped lang="scss"></style>
