import { useMainStore } from '~/store/main/main.store'

export function getOrderUrl() {
  const ctx = useNuxtApp()
  const $router = useRouter()
  const mainStore = useMainStore()
  const partner = computed(() => mainStore.getDomainConfig)
  if (partner.value) {
    $router.push('/complete/')
    return
  }

  const getUrl = async () => {
    const res = (await $fetch(`${mainStore.apiDomain}/graphql/`, {
      method: 'POST',

      body: {
        operationName: 'getShortUid',
        variables: {
          byShortUid: null,
        },
        query: 'query getShortUid($byShortUid: String) {\n  personal {\n    getInteractionOrders(byShortUid: $byShortUid) {\n      shortUid\n      __typename\n    }\n    __typename\n  }\n}',
      },
    }))?.data.personal.getInteractionOrders?.[0]?.shortUid
    nextTick(() => {
      if (res) $router.push({ path: `/personal/order/${res}/` })
      else $router.push({ path: `/personal/complete/` })
    })
  }

  return getUrl()
}
