<template>
  <LazyProviderPageCheckaddressPartner v-if="diProvider" />
  <LazyProviderPageCheckaddress v-else />
</template>

<script setup lang="ts">
import { useMainStore } from '~/store/main/main.store'
import { useCities } from '~/store/cites/cities.store'

definePageMeta({
  middleware: [
    function (to) {
      const cityStore = useCities()
      const houseUrl = computed(() => cityStore.getHouseUrl)
      const currentCity = computed(() => cityStore.getCity)
      if (houseUrl.value) {
        return navigateTo(`/${currentCity.value?.url}${to.fullPath?.toString().replace('checkaddress/', '')}`)
      }
    },
  ],
})

const mainStore = useMainStore()
const diProvider = computed(() => mainStore.getDiProvider)
</script>

<style scoped lang="scss"></style>
