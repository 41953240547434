<template>
  <LazyProviderPageSmallHeader />
  <LazyProviderPageCheckaddress />
  <LazyProviderPageFooter />
</template>

<script setup lang="ts">
</script>

<style scoped lang="scss"></style>
