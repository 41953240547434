import { useMainStore } from '~/store/main/main.store'

export default async function getCallsGeneral(input) {
  const mainStore = useMainStore()
  const res = await $fetch(`${mainStore.apiDomain}/calls/general/`, {
    method: 'GET',
    params: {
      ...input,
    },
  })
  return input?.provider_id === 6 || input?.provider_id === '6' || input?.provider_slug === 'mts'
    ? {
        ...res,
        support: null,
      }
    : res
}
