<template>
  <component :is="currentRegion && $route.path.includes(currentRegion.url) ? LazyEntryPageRegionPTA : LazyEntryPagePTA" />
</template>

<script setup lang="ts">
import { useCities } from '~/store/cites/cities.store'
import { LazyEntryPagePTA, LazyEntryPageRegionPTA } from '#components'

definePageMeta({
  middleware: [
    function (to) {
      const cityStore = useCities()
      const currentCity = computed(() => cityStore.getCity)
      if (to.path !== `/${currentCity.value?.url}/providers-to-address/` && to.path !== `/moskovskaya-obl/providers-to-address/`)
        return abortNavigation()
    },
  ],
})

const cityStore = useCities()
const $route = useRoute()
const currentRegion = computed(() => cityStore.getRegion)
</script>
