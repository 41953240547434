import { onError } from '@apollo/client/link/error'
import { createHttpLink, from } from '@apollo/client/core'
import { setContext } from '@apollo/client/link/context'
import { useMainStore } from '~/store/main/main.store'

export default defineNuxtPlugin(({ hook, callHook, $apollo }) => {
  const defaultClient = $apollo.defaultClient
  const mainStore = useMainStore()

  const {
    'x-real-ip': xRealIp,
    host,
    'x-utm-state': xUtm,
    cookie: ssrCookie,
  } = useRequestHeaders()
  const sessionidCookie = useCookie('sessionid')
  let defaultHeaders = {}
  if (sessionidCookie.value) {
    defaultHeaders = {
      'ip': xRealIp || null,
      'X-Sessionid': sessionidCookie.value,
      'X-Forwarded-Host': host,
      'X-Utm-State': xUtm,
      'cookie': ssrCookie,
    }
  }
  else {
    defaultHeaders = {
      'ip': xRealIp || null,
      'X-Forwarded-Host': host,
      'X-Utm-State': xUtm,
      'cookie': ssrCookie,
    }
  }
  if (import.meta.client) {
    const exclude = ['cookie', 'X-Utm-State', 'X-Forwarded-Host', 'ip']
    exclude.forEach((key) => {
      delete defaultHeaders[key]
    })
  }
  const authLink = setContext(async (_, { headers }) => {
    return {
      headers: {
        ...headers,
        ...defaultHeaders,
      },
    }
  })

  const errorLink = onError((err) => {
    callHook('apollo:error', err)
  })

  const httpLink = createHttpLink({
    uri: import.meta.server ? process.env.VITE_NUXT_ENV_BACKEND_GRAPHQL_ROOT_URL : `https://${mainStore.getDomain}/api/graphql/`,
  })
  defaultClient.setLink(from([
    errorLink,
    authLink,
    httpLink,
  ]),
  )
  defaultClient.defaultOptions = {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  }
  hook('apollo:error', (error) => {
    console.log('error: ', error)
  })
})
