<template>
  <LazyProviderPageSmallHeader />
  <LazyProviderPageProviderReviewsPage />
  <LazyProviderPageFooter />
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
</style>
