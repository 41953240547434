<template>
  <li>
    <template v-if="region.short_name !== 'г'">
      <span
        :href="'/' + region.url + '/' || ''"
        @click="showAll"
      >{{ (region.short_name ? region.short_name + " " : "") + region.name
      }}<i
        class="el-collapse-item__arrow el-icon-arrow-down fwb black--text"
        :class="{ rotate: isShowAll }"
      /></span>
      <LazyVCollapse :show-more="isShowAll">
        <ul>
          <li
            v-for="city in region.cities"
            :key="city.fias_id"
          >
            <a
              v-if="city.in_sitemap"
              :href="'/' + city.url + '/' || ''"
              @click.prevent="citySelect(city)"
            >{{
              (city.short_name ? city.short_name + "\u00A0" : "")
                + city.name
            }}</a>
            <button
              v-else
              @click="citySelect(city)"
            >
              {{
                (city.short_name ? city.short_name + "\u00A0" : "")
                  + city.name
              }}
            </button>
          </li>
        </ul>
      </LazyVCollapse>
    </template>
    <a
      v-else
      :href="'/' + region.url + '/' || ''"
      @click.prevent="citySelect(region)"
    >{{
      (region.short_name ? region.short_name + "\u00A0" : "") + region.name
    }}</a>
  </li>
</template>

<script setup lang="ts">
import type { BaseAddressObject } from '~/api'
import { useCities } from '~/store/cites/cities.store'
import { useMainStore } from '~/store/main/main.store'
import { useTariffs } from '~/store/tariffs/tariffs.store'

defineProps({
  region: {},
})
const cityStore = useCities()
const router = useRouter()
const tariffsStore = useTariffs()
const mainStore = useMainStore()
const isShowAll = ref<boolean>(false)

const showAll = () => {
  isShowAll.value = !isShowAll.value
}

const citySelect = async (cityResponse: BaseAddressObject) => {
  let res
  try {
    res = await $fetch(`${mainStore.apiDomain}/locations/address-objects/${cityResponse.fias_id}/ancestors/`, {
      method: 'GET',

      params: {
        include_self: true,
        morph: true,
      },
    })
  }
  catch {}
  cityStore.$patch({
    addressAncestors: res,
  })
  cityStore.house = {}

  tariffsStore.passThroughProviderId = null

  const coockieCity = useCookie('currentCity')
  coockieCity.value = cityResponse.fias_id

  // const promises = [
  //   tariffsStore.fetchArticleForTariffs(cityResponse.fias_id),
  //   tariffsStore.fetchTariffsByCity(cityResponse.fias_id)
  // ]
  // await Promise.all(promises)

  router.push(`/${cityResponse.url}/`)
}
</script>
