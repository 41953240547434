<template>
  <LazyCheckoutHeader />
  <div class="order-empty" />
  <LazyProviderPageFooter />
</template>

<script setup lang="ts">
import { useMainStore } from '~/store/main/main.store'
import removeUtm from '~/composible/removeUtm'
import { useCities } from '~/store/cites/cities.store'

definePageMeta({
  layout: 'empty',
})

const ctx = useNuxtApp()
const $route = useRoute()
const $router = useRouter()
const mainStore = useMainStore()
const cityStore = useCities()
const currentCity = computed(() => cityStore.getCity)

const client = ctx._apolloClients.default

let errorStatus
let errorMessage
const { data } = await useAsyncData(
  removeUtm($route.fullPath) + (currentCity.value?.fias_id || ''),
  async () => {
    const res = (
      await client.query({
        query: gql`
          query getShortUid($byShortUid: String) {
            personal {
              getInteractionOrders(byShortUid: $byShortUid) {
                shortUid
              }
            }
          }
        `,
        variables: {
          byShortUid: null,
        },
      })
    ).data.personal.getInteractionOrders?.[0]?.shortUid
    nextTick(() => {
      if (res) $router.replace({ path: `/personal/order/${res}/` })
      else {
        errorStatus = 404
        errorMessage = 'Page not found'
      }
      return 'return'
    })
  },
)

if (errorStatus) {
  throw createError({
    statusCode: errorStatus,
    fatal: true,
    message: errorMessage,
  })
}
</script>

<style scoped lang="scss"></style>
