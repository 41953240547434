<template>
  <template v-if="!diProvider">
    <LazyProviderPageMain />
  </template>
  <template v-else>
    <LazyProviderPageMainPartner />
  </template>
</template>

<script setup lang="ts">
import { useMainStore } from '~/store/main/main.store'

const mainStore = useMainStore()
const diProvider = computed(() => mainStore.getDiProvider)
</script>

<style scoped lang="scss"></style>
