<template>
  <div
    class="layout page-err d-flex justify-center"
    :class="{ 'gradient--bg': !provider }"
    :style="
      provider ? `background: ${color.bg_offer || '#FFF'}` : ''
    "
  >
    <div class="page-err__wrap d-flex justify-between align-center container">
      <div>
        <h1
          class="page-err__headline"
          :class="{ 'white--text': !provider }"
          :style="provider ? `color: ${color.font}` : ''"
        >
          Страница не найдена
        </h1>

        <p
          class="page-err__promo-text"
          :class="{ 'white--text': !provider }"
          :style="provider ? `color: ${color.font}` : ''"
        >
          К сожалению, запрашиваемая Вами страница не найдена. Вы можете
          поискать выгодные тарифы в вашем доме
        </p>

        <a
          href="/"
          class="page-err__btn d-flex align-center justify-center"
          :class="{ 'primary--bg white--text': !provider }"
          :style="
            provider
              ? `background: ${color.font}; color: ${color.main};`
              : ''
          "
        >
          Перейти на главную
        </a>
      </div>

      <img
        loading="lazy"
        src="@/assets/images/svg/404.svg"
        alt="404"
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import { useTariffs } from './store/tariffs/tariffs.store'
import { useMainStore } from '~/store/main/main.store'
import { useCities } from '~/store/cites/cities.store'

const props = defineProps({
  error: {
    type: Object,
    required: true,
  },
})
const ctx = useNuxtApp()
const mainStore = useMainStore()
const cityStore = useCities()
const tariffsStore = useTariffs()
const currentCity = computed(() => cityStore.getCity)
const provider = computed(() => mainStore.getDomainConfig)
const metrikaId = computed(() => mainStore.getMetrikaId)
const uaId = computed(() => mainStore.getUaId)
const providerId = computed(() => mainStore.getProviderId)
const color = computed(() => {
  if (provider.value?.provider.config?.color)
    return provider.value?.provider.config?.color
  return undefined
})
const getSuggestions = async () => {
  try {
    return await $fetch(`${mainStore.apiDomain}/suggestions/cities/`, {
      method: 'GET',
      params: {
        query: '',
        limit: 1,
        provider_ids: provider.value?.provider?.id
          ? [provider.value?.provider?.id]
          : undefined,
      },
    })
  }
  catch (e) {}
}

const chooseAddress = async () => {
  let res

  try {
    res = await $fetch(`${mainStore.apiDomain}/locations/address-objects/${data.value?.[0].fias_id}/ancestors/`, {
      method: 'GET',
      params: {
        include_self: true,
        morph: true,
      },
    })
  }
  catch (e) {
  }
  finally {
  }
  cityStore.$patch({
    addressAncestors: res,
  })
  cityStore.house = {}

  tariffsStore.passThroughProviderId = null

  const coockieCity = useCookie('currentCity')
  const coockieAddress = useCookie('currentAddress')

  coockieCity.value = data.value?.[0].fias_id
  coockieAddress.value = ''
}

const { data, error, refresh } = useAsyncData('404', async () => {
  if (!currentCity.value?.url) return await getSuggestions()
  else return [currentCity.value]
})

onBeforeMount(async () => {
  if (props.error?.statusCode === 500) {
    ctx.$sentryCaptureMessage(props.error, {
      level: 'error',
      tags: { customTag: '404' },
      extra: { header: '404' },
    })
  }
  // @ts-expect-error
  mainStore.$patch({
    errorState: ctx.error,
  })
})
onMounted(() => {
  if (data.value?.[0]) chooseAddress()
})

onBeforeUnmount(() => {
  useMainStore().$patch({
    errorState: null,
  })
})

useHead(() => ({
  script: [
    {
      children: `
        if (!window.ym) {
          window.ym = function() {
            (window.ym.a = window.ym.a || []).push(arguments);
          };
          window.ym.l = 1 * new Date();
        }
      `,
      type: 'text/partytown',
    },
    {
      hid: 'gtm',
      body: true,
      children: `(function (w, d, s, l, i) {
        w[l] = w[l] || [];
        const cookie = d.cookie.split('; ')
        const fias_id = '${currentCity.value?.fias_id || ``}'
        const page_version = d.cookie.includes('ab_test') ? d.cookie.split('; ').find(el => el.includes('ab_test')).replace('ab_test=', '') : ''
        const metrika_id = '${metrikaId.value || ``}'
        const ua_id = '${uaId.value || ``}'
        const provider_id = '${providerId.value || ``}'
        w[l].push({
          originalLocation: d.location.protocol + '//' +
            d.location.hostname +
            d.location.pathname +
            d.location.search,
          fias_id,
          page_version,
          metrika_id,
          ua_id,
          provider_id,
        });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != "dataLayer" ? "&l=" + l : "";
        j.async = true;
        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, "script", "dataLayer", "${import.meta.env.VITE_GOOGLE_TAG_MANAGER_ID}")`,
      type: 'text/partytown',
    },
  ],
}))
</script>

<style lang="scss">
</style>

<style scoped lang="scss">
.page-err {
  position: relative;
  width: 100vw;
  position: relative;
  //background: color(gradient);
  padding-top: 115px;
  padding-bottom: 132px;
  //padding-top: 128px;
  flex-grow: 1;
  height: unset !important;
  max-height: unset !important;
  min-height: 100vh !important;

  &__wrap {
    @media (max-width: getBreakpoint(tablet)) {
      padding-top: 32px;
      flex-direction: column;
      img {
        margin-top: 40px;
      }
    }
  }

  &__headline {
    font-size: 48px;
    line-height: 67px;
    margin-bottom: 16px;
    margin-top: 52px;
    //max-width: 700px;

    @media (max-width: getBreakpoint(desktop)) {
      font-size: fontSize(headline-3);
      line-height: 1.4;
    }
    @media (max-width: getBreakpoint(tablet)) {
      font-size: 26px;
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
      font-size: 26px;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      font-size: fontSize(headline-5);
    }
  }

  &__promo-text {
    font-size: fontSize(headline-8);
    line-height: 1.4;
    margin-bottom: 56px;
    font-weight: normal;
  }

  &__btn {
    width: 288px;
    height: 48px;
    border-radius: 28px;
  }
}
</style>
