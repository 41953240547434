<template>
  <LazyEntryPageStatic />
</template>

<script setup lang="ts">
const $route = useRoute()
useHead(() => ({
  script: [
    {
      children: `{
          "@context": "http://schema.org",
          "@type": "Organization",
          "address": {
            "@type": "PostalAddress",
            "addressLocality": "г. Ростов‑на‑Дону, Россия",
            "postalCode": "344000",
            "streetAddress": "ул. Города Волос, д. 102"
          },
          "url": "${'https://dominternet.ru' + $route.path}",
          "logo": "/android-icon-192x192.png",
          "email": "privet@dominternet.ru",
          "name": "ДомИнтернет",
          "legalName": "ООО «Интернет‑Тариф»",
          "telephone": "8 (800) 533 99 06",
          "taxID": "6167144829"
          }`,
      type: 'application/ld+json',
    },
  ],
}))
</script>
