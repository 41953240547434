<template>
  <component
    :is="
      currentRegion
        && currentRegion.level !== 'city'
        && $route.path === `/${currentRegion.url}/`
        ? LazyEntryPageArea
        : !streetPageLoading
          ? LazyEntryPageMain
          : null
    "
  />
</template>

<script setup lang="ts">
import { useCities } from '~/store/cites/cities.store'
import { LazyEntryPageArea, LazyEntryPageMain } from '#components'

definePageMeta({
  middleware: [
    function (to) {
      const cityStore = useCities()
      const currentCity = computed(() => cityStore.getCity)
      const street = computed(() => cityStore.getDataStreet)
      if (street.value && to.path === `/${street.value.url}/`) return navigateTo(`/${currentCity.value?.url}/streets${street.value.url.replace(currentCity.value?.url, '')}/`, { redirectCode: 301 })
    },
  ],
})

const cityStore = useCities()
const currentRegion = computed(() => cityStore.getRegion)

const streetPageLoading = computed(() => cityStore.getStreetPageLoading)
</script>
