import { useMainStore } from '~/store/main/main.store'
import { useCities } from '~/store/cites/cities.store'

export function providersComposible() {
  const ctx = useNuxtApp()
  const cityStore = useCities()
  const mainStore = useMainStore()
  const currentCity = computed(() => cityStore.getCity)
  const houseUrl = computed(() => cityStore.getHouseUrl)
  const houseHash = computed(() => cityStore.getHouseHash)
  const providerClient = computed(() => mainStore.getProviderClient)

  const client = ctx._apolloClients.default
  const fetchProviders = async (filterInut?, filterProvider?) => {
    const res = await client.query({
      query: gql`
        query getProviders(
          $input: LocationInfoInput!
          $providersInput2: BaseLocationProvidersInput
        ) {
          location {
            locationTerms(input: $input) {
              providers(input: $providersInput2) {
                providers {
                  score
                  agg {
                    availableHouseTypes
                    availableServices {
                      id
                      name
                    }
                    hasGaming
                    availableSubscriptionServices {
                      id
                    }
                    rawPriceRange {
                      min
                    }
                    promoCount
                    tariffsCount
                    priceRange {
                      min
                    }
                    speedRange {
                      max
                    }
                      
                    rawPriceRange {
                      min
                    }
                    mostlyFreeConnection
                    locationAgg {
                      rawPriceRange {
                        min
                      }
                      availableProviderSmartFilters {
                        slug
                        asQueryParam
                        name
                        tariffsInput
                      }
                      availableSmartFilters {
                        slug
                        name
                        tariffsInput
                      }
                      hasGaming
                      hasPromo
                      hasFreeConnection
                      hasWifiTariffs
                      hasIncludedDeviceKinds
                      availableHouseTypes
                      speedRange {
                        max
                        min
                      }
                      priceRange {
                        max
                        min
                      }
                      availableTechs {
                        id
                        name
                      }
                      tariffsCount
                      availableServices {
                        choices
                        id
                        name
                      }
                      availableProviders {
                        id
                        logo
                        name
                        slug
                        logoT {
                          url(options: { width: 160, halign: "left" })
                          unoptimized
                        }
                      }
                      availableServiceGroups {
                        id
                        serviceIds
                        name
                        servicesChoices
                      }
                      availableSubscriptionServices {
                        id
                        name
                        icon
                        slug
                      }
                    }
                  }
                  ratings {
                    ratingFinal
                    reviewsCount
                    ratingService
                    ratingSpeed
                    ratingStability
                    ratingConnection
                  }
                  housesCount
                  provider {
                    logo
                    id
                    name
                    slug
                    promoDisplay
                    cardTitle
                    cardDescription
                    passThroughLeads
                    logoT {
                      url(options: { width: 200, halign: "left" })
                        unoptimized
                    }
                  }
                  connectionTime {
                    displayShort
                  }
                  coverage {
                    mkdCoverage
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        input: {
          hash: houseUrl.value
            ? houseHash.value || undefined
            : undefined,
          url: houseUrl.value ? houseUrl.value : undefined,
          addrObjFiasId: currentCity.value?.fias_id,
          lowerProviders: providerClient.value ? [+providerClient.value] : undefined,
          ...filterInut,
        },
        providersInput2: {
          tariffless: false,
          ...filterProvider,
        },
      },
    })
    return res?.data?.location?.locationTerms?.providers?.providers
  }
  const fetchProvidersList = async (filterInut?, filterProvider?) => {
    const res = await client.query({
      query: gql`
        query getProviders(
          $input: LocationInfoInput!
          $providersInput2: BaseLocationProvidersInput
        ) {
          location {
            locationTerms(input: $input) {
              providers(input: $providersInput2) {
                providers {
                  provider {
                    logo
                    id
                    name
                    slug
                    promoDisplay
                    passThroughLeads
                    logoT {
                      url(options: { width: 200, halign: "left" })
                      unoptimized
                    }
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        input: {
          hash: houseUrl.value
            ? houseHash.value || undefined
            : undefined,
          url: houseUrl.value ? houseUrl.value : undefined,
          addrObjFiasId: currentCity.value?.fias_id,
          lowerProviders: providerClient.value ? [+providerClient.value] : undefined,
          ...filterInut,
        },
        providersInput2: {
          tariffless: false,
          ...filterProvider,
        },
      },
    })
    return res?.data?.location?.locationTerms?.providers?.providers
  }

  return {
    fetchProviders,
    fetchProvidersList,
  }
}
