<template>
  <template v-if="!diProvider">
    <LazyProviderPageMain />
  </template>
  <template v-else>
    <LazyProviderPageMainPartner />
  </template>
</template>

<script setup lang="ts">
import { useMainStore } from '~/store/main/main.store'

const mainStore = useMainStore()
const diProvider = computed(() => mainStore.getDiProvider)

definePageMeta({
  middleware: [
    function (to) {
      if (to.query.page) {
        return navigateTo(to.fullPath.replace(/page=\d*\&|[\?|\&]page=\d*$/, ''), { redirectCode: 301 })
      }
    },
  ],
})
</script>

<style scoped lang="scss"></style>
